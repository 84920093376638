<template>
  <modal
      @close="$emit('close')"
      class="custom-popup medium-size"
  >
    <template slot="header">
      <template v-if="editNotificationId !== ''">
        {{$t('common_edit.localization_value.value')}}
        {{$t('notification_notification.localization_value.value')}}
        №{{editNotificationId}}
      </template>
      <template v-else>
        <div class="admin-edit-item">
          {{$t('notification_createNotification.localization_value.value')}}
        </div>
      </template>
    </template>
    <template slot="body">
      <div class="custom-popup__content admin-edit-item">
         <span class="admin-edit"
               @click="editTranslate(notificationPopupTranslates)"
               v-if="$store.getters.getCurrentUserRights.additionalPermissions
                      && $store.getters.getCurrentUserRights.additionalPermissions[PERMISSIONS.updateNotification]"
         ></span>

        <template v-if="translation.length > 0">

          <div class="custom-row">
            <div class="custom-col custom-col--50 custom-col--md-100">
              <DatePickerDefault
                  :label="$t('notification_period.localization_value.value')"
                  :value="date"
                  v-bind:class="{'ui-no-valid': validation.date}"
                  :error="validation.date"
                  :errorTxt="serverError ? validationTxt['title_' + item.id] : $t(`${'error_required'}.localization_value.value`)"
                  :lang="$store.getters.getDatePickerTranslations[$store.getters.GET_LANG]"
              >
                <template slot="body">
                  <date-picker
                      v-model="date"
                      valueType="format"
                      type="datetime"
                      range
                      @change="selectDate"
                      :lang="$store.getters.getDatePickerTranslations[$store.getters.GET_LANG]"
                  ></date-picker>
                </template>
              </DatePickerDefault>
            </div>
            <div class="custom-col custom-col--50 custom-col--md-100 align-items-center d-flex">
              <DefaultCheckbox
                  :label="$t('notifications_activate.localization_value.value')"
                  :value="status"
                  @input="changeStatus"
              />
            </div>
          </div>


          <div class="custom-row"
               v-for="(item, index) in $store.getters.GET_LANGUAGES"
               :key="index"
          >
            <div class="custom-col mb-3">
              <div class="section-label mb-0">
                {{ item.name }}
              </div>
            </div>

            <div class="custom-col">
              <DefaultInput
                  v-bind:class="{'ui-no-valid': validation['title_' + item.id]}"
                  :error="validation['title_' + item.id]"
                  :errorTxt="serverError ? validationTxt['title_' + item.id] : $t(`${validationTranslate['title_' + item.id]}.localization_value.value`)"
                  :label="$t('common_title.localization_value.value')"
                  :type="'text'"
                  v-model="translation[index].title"
              />
            </div>

            <div class="custom-col">
              <TextareaDefault
                  v-bind:class="{'ui-no-valid': validation['text_' + item.id]}"
                  :error="validation['text_' + item.id]"
                  :errorTxt="serverError ? validationTxt['text_' + item.id] : $t(`${validationTranslate['text_' + item.id]}.localization_value.value`)"
                  :label="$t('common_text.localization_value.value')"
                  v-model="translation[index].text"
              />
            </div>

          </div>
        </template>

      </div>


    </template>
    <template slot="footer">
      <div class="buy-label__btn d-flex align-items-center justify-content-between">
        <span class="site-link site-link--alt"
              @click="$emit('close')"
        >
         {{$t('common_cancel.localization_value.value')}}
        </span>

        <MainButton
            :value="$t('common_create.localization_value.value')"
            class="buy-label__btn-i"
            @click.native="createNotification"
            v-if="editNotify === false"
            v-bind:class="{'disabled-btn' : $store.getters.getNotificationBtn}"
        />

        <MainButton
            :value="$t('common_saveChange.localization_value.value')"
            class="buy-label__btn-i wfc"
            @click.native="editNotification"
            v-if="editNotify === true"
            v-bind:class="{'disabled-btn' : $store.getters.getNotificationBtn}"
        />
      </div>
    </template>
  </modal>
</template>

<script>
  import Modal from "../../../../commonModals/Modal";
  import DefaultInput from "../../../../UI/inputs/DefaultInput/DefaultInput";
  import TextareaDefault from "../../../../UI/textarea/TextareaDefault/TextareaDefault";
  import DatePicker from 'vue2-datepicker';
  import DatePickerDefault from "../../../../UI/inputs/DatePickerDefault/DatePickerDefault";
  import MainButton from "../../../../UI/buttons/MainButton/MainButton";
  import DefaultCheckbox from "../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
  import {validation} from "../../../../../services/validation";
  // import {validation} from "../../../../../services/validation";

  export default {
    name: "EditNotificationPopup",
    components: {
      Modal,
      DefaultInput,
      TextareaDefault,
      DatePicker,
      DatePickerDefault,
      MainButton,
      DefaultCheckbox,
    },

    props: [
      'editNotificationId'
    ],


    data() {
      return {
        editNotify: false,

        translation: [],

        date: [],

        status: false,

        serverError: false,

        validation: {
          date: false,
        },

        validationTranslate: {
          date: '',
        },

        validationTxt: {
          date: false,
        },

        notificationPopupTranslates: [
          'common_edit ',
          'notification_notification ',
          'notification_createNotification',
          'notification_period',
          'notifications_activate',
          'common_title',
          'common_text',
          'common_cancel',
          'common_create',
          'common_saveChange',
        ],

      }
    },


    created() {

      if (this.editNotificationId !== '') {
        this.editNotify = true
        this.$store.dispatch('getNotification', this.editNotificationId).then(() => {

          this.$store.getters.GET_LANGUAGES.map((item) => {
            this.translation.push({
              title: this.$store.getters.getNotificationItem.translationStorage[item.locale].title,
              text: this.$store.getters.getNotificationItem.translationStorage[item.locale].text,
            })

            this.validation['text_' + item.id] = false
            this.validationTxt['text_' + item.id] = ''
            this.validationTranslate['text_' + item.id] = false
            this.validation['title_' + item.id] = false
            this.validationTxt['title_' + item.id] = ''
            this.validationTranslate['title_' + item.id] = false

          })

          this.date = [this.$store.getters.getNotificationItem.start_date, this.$store.getters.getNotificationItem.end_date]
          this.$store.getters.getNotificationItem.status === 'active' ? this.status = true : this.status = false



        }).catch(error => this.createErrorLog(error))
      } else {
        this.$store.getters.GET_LANGUAGES.map((item) => {
          this.translation.push({
            title: '',
            text: '',
          })

          this.validation['text_' + item.id] = false
          this.validationTxt['text_' + item.id] = ''
          this.validationTranslate['text_' + item.id] = false
          this.validation['title_' + item.id] = false
          this.validationTxt['title_' + item.id] = ''
          this.validationTranslate['title_' + item.id] = false

        })

      }




    },

    methods: {
      selectDate(data) {
        console.log(data)
        // this.$store.dispatch('getNotification', this.editNotificationId).then(() => {
        //   between_start_end
        // })
        // {{ item.created_at | moment("DD MMM, YYYY") }}
        // console.log(this.$moment().day());
      },

      changeStatus(val){
        this.status = val
      },

      createNotification() {

        let validationItems = {}
        let translationArray = {}
        let data = {}
        let validationOptions = {}

        this.translation.map((item, index) => {
          validationItems['title_' + this.$store.getters.GET_LANGUAGES[index].id] = item.title
          validationItems['text_' + this.$store.getters.GET_LANGUAGES[index].id] = item.text

          validationOptions['text_' + this.$store.getters.GET_LANGUAGES[index].id] = {
            type: [
              'empty'
            ]
          }

          validationOptions['title_' + this.$store.getters.GET_LANGUAGES[index].id] = {
            type: [
              'empty'
            ]
          }

          translationArray[this.$store.getters.GET_LANGUAGES[index].id] = {
            'title': item.title,
            'text': item.text
          }
        })


        let validate = validation(validationItems, validationOptions)
        this.validation = validate.validation
        this.validationTranslate = validate.validationError

        data.translations = translationArray

        if (this.date.length > 0 && this.date[0] !== null && this.date[1] !== null) {
          data.start_date = this.date[0]
          data.end_date = this.date[1]
          this.validation.date = false
        } else {
          this.validation.date = true
        }

        this.status ? data.status = 'active' : data.status = 'inactive'



        if(validate.isValidate){
          this.serverError = false;

          this.$store.dispatch('createNotifications', data).then((response) => {

            switch (this.getResponseStatus(response)) {
                /**
                 * Success
                 */
              case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {

                this.GlobalGetNotifivationList()

                this.$emit('close')

                setTimeout(() => {
                  this.openNotify('success', 'common_notificationNotificationCreated')
                }, 200)
                break
              }
                /**
                 * Validation Error
                 */
              case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
                let errors = response.response.data.errors;
                this.notifyErrorHelper(errors)
                break
              }
                /**
                 * FORBIDDEN CODE
                 */
              case this.$store.getters.GET_ERRORS.FORBIDDEN_CODE: {
                this.checkAccess2faAt()
                break
              }
                /**
                 * Undefined Error
                 */
              default: {
                this.openNotify('error', 'common_notificationUndefinedError')
              }
            }

          })
        }
      },



      editNotification(){
        let validationItems = {}
        let translationArray = {}
        let data = {}
        let validationOptions = {}

        this.translation.map((item, index) => {
          validationItems['title_' + this.$store.getters.GET_LANGUAGES[index].id] = item.title
          validationItems['text_' + this.$store.getters.GET_LANGUAGES[index].id] = item.text

          validationOptions['text_' + this.$store.getters.GET_LANGUAGES[index].id] = {
            type: [
              'empty'
            ]
          }

          validationOptions['title_' + this.$store.getters.GET_LANGUAGES[index].id] = {
            type: [
              'empty'
            ]
          }

          translationArray[this.$store.getters.GET_LANGUAGES[index].id] = {
            'title': item.title,
            'text': item.text
          }
        })


        let validate = validation(validationItems, validationOptions)
        this.validation = validate.validation
        this.validationTranslate = validate.validationError

        data.translations = translationArray


        if (this.date !== '' && this.date[0] !== null && this.date[1] !== null) {
          data.start_date = this.date[0]
          data.end_date = this.date[1]
        } else {
          alert('data')
        }

        this.status ? data.status = 'active' : data.status = 'inactive'



        if(validate.isValidate){
          this.serverError = false;
          this.$store.dispatch('updateNotification', { id: this.editNotificationId, data: data}).then((response) => {

            if (this._.has(response, 'data') && response.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {
              console.log(response);

              this.GlobalGetNotifivationList()

              this.$emit('close')

              setTimeout(() => {
                this.openNotify('success', 'common_notificationNotificationChanged')
              }, 200)


            } else if (response.response.status === this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE) {

              let errors = response.response.data.errors;


              this.serverError = true;
              errors.title ? (this.validation.title = true, this.validationTxt.title = errors.title[0]) : false;
              errors.text ? (this.validation.text = true, this.validationTxt.text = errors.text[0]) : false;
            }

          })
        }
      }
    },


  }
</script>

<style scoped>

</style>
