<template>
  <div class="page-content">
<!--    <Breadcrumb/>-->
<!--    <SearchEngine-->
<!--        v-if="isAdmin"-->
<!--    />-->
<!--    <div class="detail-page notification-page">-->
<!--      <NotificationsList/>-->
<!--    </div>-->

    <NotificationModule

    />

  </div>
</template>

<script>
  // import Vue from 'vue'
  // import Breadcrumb from "../../templates/Breadcrumb/Breadcrumb.vue";
  // import NotificationsList from "./components/NotificationsList/NotificationsList";
  // import SearchEngine from "../../coreComponents/SearchEngine/SearchEngine";

  import NotificationModule from "@/components/modules/NotificationModule/NotificationModule";
  export default {
    name: "NotificationListModule",

    components: {
      NotificationModule
      // SearchEngine,
      // Breadcrumb,
      // NotificationsList,
    },

    data() {
      return {
        // date: null,
        // isEditPopup: false,
        // editNotificationId: '',
        // filterDate: [],
        // forPage: 25,
        // page: 1,
        // flagPeriod: false,
      }
    },

    mounted() {
      // this.filter()
      //
      // Vue.prototype.GlobalGetNotifivationList = () => {
      //   this.resetFilter()
      // }
    },

    methods: {


      // selectDate(data) {
      //   if (data[0] === null && data[1] === null) {
      //     this.filterDate = []
      //   } else {
      //     this.filterDate = data
      //   }
      //   this.filter()
      // },
      //
      // createNotificationPopup() {
      //   this.editNotificationId = ''
      //   this.isEditPopup = true
      // },
      //
      // closeEditNotificationPopupHandler() {
      //   this.isEditPopup = false
      // },
      //
      // editNotification(id) {
      //   this.editNotificationId = id
      //   this.isEditPopup = true
      // },
      //
      //
      // changeFilter(data) {
      //
      //   this.filterKey = data.filterKey
      //   this.page = 1
      //
      //   this.filter()
      // },
      //
      // resetFilter() {
      //   // this.filterId = ''
      //   this.filterDate = []
      //   this.forPage = 25
      //   this.page = 1
      //
      //   this.filter()
      // },
      //
      // filter(next = false) {
      //
      //   const query = new this.Query();
      //   let url = '?';
      //   let myQuery = query
      //     .for('posts')
      //
      //   if (this.filterDate.length > 0) {
      //     !this.$store.getters.getUserProfile.isAdmin ?
      //       myQuery.whereIn('between_start_end', [this.$moment(this.filterDate[0]).format('YYYY-MM-DD'), this.$moment(this.filterDate[1]).format('YYYY-MM-DD')]) :
      //       myQuery.whereIn('between_created_at', [this.$moment(this.filterDate[0]).format('YYYY-MM-DD'), this.$moment(this.filterDate[1]).format('YYYY-MM-DD')])
      //   }
      //
      //   url = url + myQuery.limit(this.forPage).page(this.page).url().split('?')[1]
      //
      //   if (next) this.$store.commit('setNextNotificationPage', true)
      //
      //   this.$store.dispatch('fetchNotifications', url).then(() => {
      //     this.$store.commit('setNextNotificationPage', false)
      //
      //     let newDate = [
      //       this.$moment(this.$store.getters.getNotifications[this.$store.getters.getNotifications.length - 1].created_at).format('YYYY-MM-DD'),
      //       this.$moment(this.$store.getters.getNotifications[0].created_at).format('YYYY-MM-DD')
      //     ]
      //     console.log(11111, newDate)
      //     console.log(newDate);
      //     this.date = newDate
      //   }).catch(error => this.createErrorLog(error))
      //
      // },
      //
      // showMore() {
      //   this.page = this.page + 1
      //
      //   this.filter(true)
      // },

    }
  }
</script>

<style lang="scss">
</style>
