<template>
  <div class="page-content">
    <Breadcrumb/>
    <SearchEngine
        v-if="isAdmin"
    />
    <div class="detail-page notification-page">
      <div class="detail-page__left">
        <CardLeftBlock class="notification-page__left-block"
                       :backgroundImage="'notifications'"
        >
          <template slot="body">
            <div class="notification-page__period">
              <div class="notification-page__period-title"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['notification_period'])"></div>

                {{ $t('notification_period.localization_value.value') }}
              </div>
              <div class="notification-page__datepicker">
                <DatePickerDefault>
                  <template slot="body">
                    <date-picker
                            v-model="date"
                            ref="datePicker"
                            valueType="format"
                            range
                            @change="selectDate"
                            :lang="$store.getters.getDatePickerTranslations[$store.getters.GET_LANG]"

                    ></date-picker>
                    <!--:placeholder="'yyyy-mm-dd ~ yyyy-mm-dd'"-->
                  </template>
                </DatePickerDefault>
              </div>
              <div class="notification-page__btn"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['notification_createNotification'])"></div>
                <MainButton
                        v-if="isAdmin"
                        class="wfc mt-3 mb-3"
                        :value="$t('notification_createNotification.localization_value.value')"
                        @click.native="createNotificationPopup"
                />

                <EditNotificationPopup
                        @close="closeEditNotificationPopupHandler"
                        v-if="isEditPopup"
                        :editNotificationId="editNotificationId"
                />
              </div>
            </div>
          </template>
        </CardLeftBlock>
      </div>
      <div class="detail-page__right">
        <NotificationsList
                @edit="editNotification"
                @showMore="showMore"
                :forPage="forPage"
        />
      </div>
    </div>

  </div>
</template>

<script>
  import Vue from 'vue'
  import Breadcrumb from "../../templates/Breadcrumb/Breadcrumb.vue";
  import CardLeftBlock from "../../coreComponents/CardLeftBlock/CardLeftBlock.vue";
  import DatePicker from 'vue2-datepicker';
  import DatePickerDefault from "../../UI/inputs/DatePickerDefault/DatePickerDefault";
  import NotificationsList from "./components/NotificationsList/NotificationsList";
  import MainButton from "../../UI/buttons/MainButton/MainButton";
  import EditNotificationPopup from "./popups/EditNotificationPopup/EditNotificationPopup";
  import SearchEngine from "../../coreComponents/SearchEngine/SearchEngine";

  export default {
    name: "NotificationModule",

    components: {
      SearchEngine,
      Breadcrumb,
      CardLeftBlock,
      DatePickerDefault,
      DatePicker,
      NotificationsList,
      MainButton,
      EditNotificationPopup,
    },

    data() {
      return {
        date: null,
        isEditPopup: false,
        editNotificationId: '',
        filterDate: [],
        forPage: 25,
        page: 1,
        flagPeriod: false,
      }
    },

    mounted() {
      this.filter()

      Vue.prototype.GlobalGetNotifivationList = () => {
        this.resetFilter()
      }
    },

    methods: {


      selectDate(data) {
        if (data[0] === null && data[1] === null) {
          this.filterDate = []
        } else {
          this.filterDate = data
        }
        this.filter()
      },

      createNotificationPopup() {
        this.editNotificationId = ''
        this.isEditPopup = true
      },

      closeEditNotificationPopupHandler() {
        this.isEditPopup = false
      },

      editNotification(id) {
        this.editNotificationId = id
        this.isEditPopup = true
      },


      changeFilter(data) {

        this.filterKey = data.filterKey
        this.page = 1

        this.filter()
      },

      resetFilter() {
        // this.filterId = ''
        this.filterDate = []
        this.forPage = 25
        this.page = 1

        this.filter()
      },

      filter(next = false) {

        const query = new this.Query();
        let url = '?';
        let myQuery = query
          .for('posts')

        if (this.filterDate.length > 0) {
          !this.$store.getters.getUserProfile.isAdmin ?
            myQuery.whereIn('between_start_end', [this.$moment(this.filterDate[0]).format('YYYY-MM-DD'), this.$moment(this.filterDate[1]).format('YYYY-MM-DD')]) :
            myQuery.whereIn('between_created_at', [this.$moment(this.filterDate[0]).format('YYYY-MM-DD'), this.$moment(this.filterDate[1]).format('YYYY-MM-DD')])
        }

        url = url + myQuery.limit(this.forPage).page(this.page).url().split('?')[1]

        if (next) this.$store.commit('setNextNotificationPage', true)

        this.$store.dispatch('fetchNotifications', url).then(() => {
          this.$store.commit('setNextNotificationPage', false)

          let newDate = [
            this.$moment(this.$store.getters.getNotifications[this.$store.getters.getNotifications.length - 1].created_at).format('YYYY-MM-DD'),
            this.$moment(this.$store.getters.getNotifications[0].created_at).format('YYYY-MM-DD')
          ]
          console.log(11111, newDate)
          console.log(newDate);
          this.date = newDate
        }).catch(error => this.createErrorLog(error))

      },

      showMore() {
        this.page = this.page + 1

        this.filter(true)
      },

    }
  }
</script>

<style lang="scss">
  @import "./NotificationModule";
</style>
