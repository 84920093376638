<template>
  <div class="notifications-list">
    <template v-if="$store.getters.getNotificationsLoading === false && $store.getters.getNotifications.length === 0">
      <NoResult
              :countFilterParams="0"
              :title="$t('common_noDataYet.localization_value.value')"
              :titleWithFilter="$t('common_noResult.localization_value.value')"
              :text="$t('common_noDataYetTxt.localization_value.value')"
              :textWithFilter="$t('common_noResultSeems.localization_value.value')"
      />
    </template>
    <template
      v-else
    >
      <div class="notifications-list__item"
           v-for="(item, index) in $store.getters.getNotifications"
           :key="index"
           :class="{ viewed : item.isViewed}"
      >
        <div class="notifications-list__btn">
          <ManagerButton @reloadManagerBtn="$store.commit('setGlobalUpdateManagerButton', Math.random()) " :key="$store.getters.getGlobalUpdateManagerButton" class="notifications-list__manager-btn alone"
                         :position="'bottom'"
          >
            <template slot="item">
              <LinkButton
                  :value="$t('common_detail.localization_value.value')"
                  :type="'docs'"
                  @click.native="viewNotification(item, index)"
              />
            </template>
<!--                      v-if="_.has(currentPermissions, PERMISSIONS.UPDATE_NOTIFICATION)"-->
            <template slot="item"
                      v-if="isAdmin"
            >
              <LinkButton
                  :value="$t('common_edit.localization_value.value')"
                  :type="'edit'"
                  @click.native="$emit('edit', item.id)"
              />
            </template>
<!--                      v-if="_.has(currentPermissions, PERMISSIONS.DELETE_NOTIFICATION)"-->
            <template slot="item"
                      v-if="isAdmin"
            >
              <LinkButton
                  :value="$t('common_delete.localization_value.value')"
                  :type="'delete'"
                  @click.native="deleteNotification(item.id)"
              />
            </template>
          </ManagerButton>
        </div>
        <div class="notifications-list__date">
          <div class="notifications-list__status"
               v-if="$store.getters.getCurrentUserRights.additionalPermissions && $store.getters.getCurrentUserRights.additionalPermissions[PERMISSIONS.deleteNotification]"
          >
            <StatusIcoBtn :type="item.status === 'active' ? 'active' : 'declined'" class="mr-1"/>
          </div>

          {{ item.created_at | moment("DD MMM, YYYY") }}
        </div>

        <div class="notifications-list__title">
          {{item.translationStorage[$store.getters.GET_LANG].title}}
        </div>
        <div class="notifications-list__txt">
          {{item.translationStorage[$store.getters.GET_LANG].text}}
        </div>

      </div>

      <div class="table-bottom-btn" v-if="$store.getters.getNotificationsCommonData.next_page_url !== null">
        <div class="table-bottom-btn__inner">
          <ShowMore class="table-bottom-btn__right" @click.native="$emit('showMore')"
                    :count="$store.getters.getNotificationsCommonData.total - forPage < forPage ? $store.getters.getNotificationsCommonData.total - forPage: forPage"/>
        </div>
      </div>
    </template>

  </div>
</template>

<script>
  import ManagerButton from "../../../../UI/buttons/ManagerButton/ManagerButton";
  import LinkButton from "../../../../UI/buttons/LinkButton/LinkButton";
  // import DetailNotificationPopup from "../../popups/DetailNotificationPopup/DetailNotificationPopup";
  import ShowMore from "../../../../coreComponents/Buttons/ShowMore/ShowMore";
  import StatusIcoBtn from "../../../../UI/status/StatusIcoBtn/StatusIcoBtn";
  import NoResult from "../../../../coreComponents/NoResult/NoResult";

  export default {
    name: "NotificationsList",
    components: {
      ManagerButton,
      LinkButton,
      // DetailNotificationPopup,
      ShowMore,
      StatusIcoBtn,
      NoResult,
    },

    props: [
      'isEditPopup',
      'forPage'
    ],

    data(){
      return {

        showDetail: false,
        notificationItem: ''
      }
    },



    methods: {
      closeDetailHandler(){
        this.showDetail = false
      },

      // showDetailNotification(item){
      //   // this.showDetail = true
      //   this.$store.commit('setOpenNotificationPopup', true)
      //   this.notificationPopup(item.id)
      //   // this.notificationItem = item
      // },


      // deleteNotificationItem(id){
      //   this.$store.dispatch('deleteNotification', id).then(() => {
      //     this.GlobalGetNotifivationList()
      //
      //     setTimeout(() => {
      //       this.openNotify('success', 'common_notificationRecordDeleted')
      //     }, 200)
      //   })
      // },

      viewNotification(item) {
        this.notificationPopup(item.id)
      },

      deleteNotification(id) {

        let text = {
          title: 'notification_deleteNotificationItem',
          txt: 'notification_deleteNotificationItemQuest',
          yes: 'common_confirmDelete',
          no: 'common_confirmNo'
        }

        let confirm = () => {

          this.$store.dispatch('deleteNotification', id).then(() => {
            this.GlobalGetNotifivationList()

            setTimeout(() => {
              this.openNotify('success', 'common_notificationRecordDeleted')
            }, 200)
          })

          this.$store.commit('setConfirmDeletePopup', false)
          return true
        }

        this.deleteFunc(text, confirm)
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "./NotificationsList";
</style>
